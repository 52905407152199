
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userWallet: "" || sessionStorage.getItem("userWallet"),
    phone: "" || sessionStorage.getItem("phone"),
    language: sessionStorage.getItem('yy') || "en-US",
     theame:localStorage.getItem('theame') || '0',
  },
  mutations: {
    userWallet(state, val) {
      // state.userWallet = val;
      Vue.set(state,'userWallet',val)
      sessionStorage.setItem("userWallet", val);
    },
    language(state,val){
      state.language = val
      // Vue.set(state,'yy',val)
      sessionStorage.setItem('yy', val)
    },
    phone(state, val) {
      // state.usdt = val;
      Vue.set(state,'phone',val)
      sessionStorage.setItem("phone", val);
    },   
    theame(state, val) {
      // state.theame = val;
      Vue.set(state,'theame',val)
      localStorage.setItem("theame", val);
    },
  },
  actions: {},
  modules: {},
  getters: {
    userWallet: (state) => state.userWallet,
    phone: (state) => state.phone,
    language: (state) => state.language,
    theame: (state)=>state.theame,
  },
});
