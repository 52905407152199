let ethereum = window.ethereum
    let i = setInterval(() => {
    if ((typeof window.ethereum) !== 'undefined') {
        ethereum = window.ethereum      
        handleWatch()  
        clearInterval(i)  
    }
    }, 1000);

export async function set(){
  ethereum = window.ethereum   
        // console.log('hy',window.ContractEth);
}
export async function handleWatch () {
    if (!window.ethereum) {
        console.log('Metamask is not installed!')
        return false
      }
    window.ethereum.on('accountsChanged',  (accounts) => {
      if(accounts[0]){
        // let web3s = new Web3(ethereum);
        // let msg = web3s.utils.keccak256('授权签名');
        // let ht= await web3s.eth.sign(msg, accounts[0])
        // .then(res=>{return res});  
        // if(ht){
      sessionStorage.setItem('userWallet',accounts[0])  
    //   store.commit("userWallet", accounts[0]);  
    }else{
        sessionStorage.removeItem('userWallet')
      }     
    })
  }
export async function connectTron() {
    const eth_requestAccounts = await ethereum.request({ method: "eth_requestAccounts" });
    // store.commit("userWallet", eth_requestAccounts[0]);
    // return ['0x4A17A0572c96b2Aa186a0224193eBa587444be44']
    return eth_requestAccounts
}











