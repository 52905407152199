<template>
  <div class="buttoms" :class="show ? 'h5' : ''">
    <div class="box">
    <div class="logo">
      <img src="../assets/img/logo.png" alt=""  class="img" />
    </div>
    <div class="btngroup">
        <div class="icon" @click="togo('https://x.com/pinkfinance_')">
          <img src="../assets/img/ico1.png" alt=""  class="img2" />
        </div>
        <div class="icons" @click="togo('https://pinkfinance.gitbook.io/pinkfinance/')">
          <img src="../assets/img/ico3.png" alt=""  class="img2" />
        </div>
    </div>
  </div>
  </div>
  </template>
  <script>
  export default {
    data(){
      return{
        show:false,
      }
    },
    watch: {
    "$store.getters.phone": function (val) {
      this.show = val;
    }
  },
    mounted() {
    if (this._isMobile()) {
      this.show = true;
      // this.wallet()
    } else {
      this.show = this.$store.getters.phone;
    }
  },
    methods:{
      _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
      togo(url){
        window.open(url)
      }
    }
  }
  </script>
  <style lang="scss" scoped>  
  .buttoms {
    font-size: 24px;
    font-family: "PingFang";
    font-weight: bold;
    color: #999999;
    line-height: 28px;
    position: relative;
    bottom: 0;
    left: 50%;
    width: 100%;
    height: 150px;
    background: #000000;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .box{
      max-width: 1440px;
      width: 77%;
      margin: 0 auto;
      display: flex;
    align-items: center;
    justify-content: space-between;
    }
    .logo{
      width: 105px;
      .img{
        width: 105px;
      }
    }
    .btngroup{
      display: flex;
      align-items: center;
      .icon{
        width: 32px;
        height: 32px;
        border-radius: 100%;
        background: #ffffff;
        display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 24px;
      cursor: pointer;
        .img2{
            width: 20px;
            height: 20px;
        }
      }
      .icons{
        width: 32px;
        height: 32px;
        border-radius: 100%;
        background: #ffffff;
        display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
        .img2{
            width: 20px;
            height: 20px;
        }
      }
    }
  }
  .h5{
    height: 384px;
    .box{
    width: 80%;
    flex-direction: column;
    .logo{
      width: 144px;
      .img{
        width: 144px;
      }
    }
    .btngroup{
      margin-top: 56px;
      .icon{
        width: 64px;
        height: 64px;
      margin-right: 48px;
        .img2{
            width: 29px;
            height: 29px;
        }
      }
      .icons{
        width: 64px;
        height: 64px;
        .img2{
            width: 29px;
            height: 29px;
        }
      }
    }
    }
  }
</style>